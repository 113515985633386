.container {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 10px;
}
.header {
    position: absolute;
    z-index: 999;
    width: 100%;
    top: 0;
    left: 0;
    .main-header {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            img {
                width: 154px;
            }
        }
        .main-menu {
            .list-menu {
                display: flex;
                align-items: center;
                li {
                    list-style-type: none;
                    a {
                        font-family: 'Wicked Mouse', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26.35px;
                        letter-spacing: 0.01em;
                        text-align: center;
                        display: block;
                        padding: 10px 15px;
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                }
            }
        }
        .main-button {
            cursor: pointer;
            img {
                width: 213px;
                display: block;
            }
        }
    }
}
.section-banner {
    background: url("https://s3-alpha-sig.figma.com/img/bff5/c70f/5869d1ccc1422e12d658dd5b82fc6af4?Expires=1721606400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nLcHSPHV6ezmsQYK6Hp~8mytKZWL1j2nYvVKfS17LEhiH8pwTFXFPDIJJTpH2O2tseb6gsYfMYgvy6UFQFokO0v2od0NP3bdq9wuId10LecmSLWnjVASHEHAF4Cmfn6rHDQUo~LL8DSfxhhnbKSsP5bFz-Oj7GV5NVMSrtQNUmBrJ4QBy-te75RGMcucnB0HPNjN2noMQkwsfIjPhNH0trMOIYWqMMaOpm3u2QCuw76rD0Yd6QO4QEZ3s2ImQaqao7CwXKxQF4kJh-KFKJaexXeMpmAxQ-8tYhky3-x5C~G62vMq-sqgaLffAFyB10b0Zw5cdmwwgu9rK87gJiaSdA__");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 120px;
    background-attachment: fixed;
    .main-banner {
        .top-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .left {
                width: 50%;
                .box-img {
                    text-align: center;
                }
            }
            .right {
                width: 50%;
                .wrap-content {
                    .small-title {
                        font-family: 'Garfield', sans-serif;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 27.6px;
                        letter-spacing: 0.01em;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 15px;
                    }
                    .title {
                        margin-bottom: 15px;
                        img {
                            width: 616px;
                            display: block;
                        }
                    }
                    .desc {
                        font-family: 'Garfield', sans-serif;
                        font-size: 32px;
                        line-height: 37.5px;
                        letter-spacing: 0.01em;
                        text-align: center;
                        color: #fff;
                    }
                }
            }
        }
        .center-content {
            margin-top: 40px;
            .main-desc {
                font-family: 'Garfield', sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0.01em;
                text-align: center;
                color: #fff;
                margin-bottom: 20px;
            }
            .contract-address {
                border: 3px solid #000000;
                padding: 10px;
                background: #fff;
                font-family: 'Garfield', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 18.4px;
                letter-spacing: 0.01em;
                text-align: center;
                color: #000;
                width: 500px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    display: block;
                    margin-right: 10px;
                }
            }
            .gr-buy {
                margin-top: 20px;
                .list-dex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 800px;
                    margin: 0 auto;
                    li {
                        list-style-type: none;
                        a {
                            display: block;
                            text-decoration: none;
                            font-family: 'Garfield', sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.01em;
                            text-align: center;
                            border: 3px solid #000000;
                            background: #51A5CA;
                            color: #000;
                            padding: 21px 50px;
                        }
                    }
                }
            }
        }
    }
}
.section-grafield {
    background: #FFF68D;
    padding-top: 50px;
    position: relative;
    .line {
        position: absolute;
        bottom: -3px;
        left: 0;
        z-index: 0;
        width: 100%;
        img {
            width: 100%;
        }
    }
    .main-grafield {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: #FFF68D;
        padding: 40px 80px 40px 120px;

        .left {
            width: 30%;
            position: relative;
            .box-img {
                img {
                    width: 364px;
                }
            }
        }
        .right {
            width: 70%;
            position: relative;
            .content-row {
                width: 80%;
                margin: 0 auto;
                padding: 30px;
                background: #fff;
                border: 3px solid #000000;
                .title {
                    font-family: 'Garfield', sans-serif;
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 46px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #F59E35;
                    margin-bottom: 15px;
                }
                .desc {
                    font-family: 'Garfield', sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 31.3px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #000;
                    padding-bottom: 50px;
                }
            }
        }
    }
}
.main-button {
    display: flex;
    align-items: center;
    .item {
        padding: 0 5px;
        cursor: pointer;
    }
}
.section-map {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    padding-top: 500px;
    background-color: #000;
    position: relative;

    .main-map {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .left {
            width: 50%;
            .box-img {
                img {
                    width: 552px;
                }
            }
        }
        .right {
            width: 50%;
            .content-map {
                .title {
                    font-family: 'Garfield', sans-serif;
                    font-size: 60px;
                    font-weight: 400;
                    line-height: 68.99px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    margin-bottom: 30px;
                    color: #fff;
                }
                .item {
                    font-family: 'Garfield', sans-serif;
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 46px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.section-ultilies {
    background: #000;
    padding: 120px 0;
    .main-ultilies {
        .title {
            font-family: 'Garfield', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 68.99px;
            letter-spacing: 0.01em;
            text-align: center;
            color: #F59E35;
            margin-bottom: 25px;
        }
        .round-now {
            padding: 30px 40px;
            background: #51A5CA;
            border: 3px solid #000000;
            width: 80%;
            margin: 0 auto 30px;
            &:last-child {
                margin-bottom: 0;
            }
            .txt {
                font-family: 'Garfield', sans-serif;
                font-size: 30px;
                font-weight: 400;
                line-height: 46px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #FFF68D;
                margin-bottom: 15px;
            }
            .desc {
                font-family: 'Garfield', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 31.3px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #fff;
            }
        }
    }
}
.section-avaiable {
    background: #F59E35;
    box-shadow: -27px 19px 0px 0px #F2B973B2 inset;
    box-shadow: 12px 5px 0px 0px #000000;
    padding: 120px 0;
    .main-avaiable {
        .title {
            font-family: 'Garfield', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 68.99px;
            letter-spacing: 0.01em;
            text-align: center;
            margin-bottom: 50px;
            color: #fff;
        }
        .round-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item {
                width: 29%;
                border: 3px solid #000000;
                padding: 20px;
                margin-bottom: 20px;
                background: #fff;
                span {
                    img {
                        margin-top: 5px;
                        width: auto;
                    }
                }
                &:nth-child(3) {
                    img {
                        width: auto;
                    }
                }
                // &:nth-child(4) {
                //     margin-right: auto;
                // }
                &:nth-child(5) {
                    margin-right: auto;
                    margin-left: 20px;
                }
                img {
                    width: 250px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
.footer {
    background: #000;
    padding: 120px 0;
    .main-footer {
        text-align: center;
        .logo {
            margin-bottom: 30px;
            img {
                width: 490px;
            }
        }
        .desc {
            font-family: 'Garfield', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 31.3px;
            letter-spacing: 0.01em;
            text-align: center;
            color: #fff;
            margin-bottom: 30px;
        }
        .social-name {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            .item {
                margin: 0 10px;
                cursor: pointer;
                img {
                    width: 80px;
                    height: 80px;
                    display: block;
                    transition: 0.3s ease;
                    background: #FFF68D;
                    &:hover {
                        background: #F59E35;
                    }
                }
            }
        }
    }
}

.section-buy-work {
    background: #000;
    padding: 300px 0 120px;
    position: relative;
    .top-buy {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 45px;
    }
    .main-buy {
        background: #fff;
        padding: 100px 40px 40px 40px;
        position: relative;
        .left-buy {
            position: absolute;
            right: -10%;
            bottom: 10%;
        }
        .title {
            font-family: 'Garfield', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 68.99px;
            letter-spacing: 0.01em;
            text-align: left;
            color: rgba(245, 158, 53, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 60px;
            img {
                width: 98px;
                margin: 0 20px;
            }
        }
        .item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 70px;
            padding: 0 170px 0 70px;
            &:last-child {
                margin-bottom: 0;
            }
            .icon {
                width: 95px;
            }
            .desc-row {
                width: calc(100% - 145px);
                .txt {
                    font-family: 'Garfield', sans-serif;
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 46px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #000;
                    margin-bottom: 20px;
                }
                .desc {
                    font-family: 'Garfield', sans-serif;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 31.3px;
                    letter-spacing: 0.01em;
                    text-align: left;

                }
            }
        }
    }
}

.row-contact {
    display: flex;
    flex-wrap: wrap;
    width: 800px;
    margin: 40px auto 0;
    justify-content: space-between;
    .item {
        width: 45%;
        border: 3px solid rgba(0, 0, 0, 1);
        background: rgba(255, 255, 255, 1);
        font-family: Garfield;
        font-size: 24px;
        font-weight: 400;
        line-height: 27.6px;
        letter-spacing: 0.01em;
        text-align: center;
        padding: 10px;        
    }
}

@media screen and (max-width: 768px) {
    .section-banner .main-banner .top-content .left {
        width: 100%;
    }
    .section-banner .main-banner .top-content .right {
        width: 100%;
    }
    .section-banner .main-banner .top-content .right .wrap-content .title img {
        width: 100%;
    }
    .section-banner .main-banner .top-content .left .box-img img {
        width: 100%;
    }
    .main-menu {
        display: none;
    }
    .header .main-header .main-button img {
        width: 130px;
    }
    .header .main-header .logo img {
        width: 130px;
    }
    .container {
        padding: 0;
    }
    .header .main-header {
        padding: 10px;
    }
    .section-banner .main-banner .top-content .right .wrap-content .desc {
        font-size: 24px;
    }
    .section-banner .main-banner .center-content .main-desc {
        font-size: 16px;
    }
    .section-banner .main-banner .center-content .contract-address {
        width: 93%;
    }
    .main-banner {
        padding: 0 10px;
    }
    .section-banner .main-banner .center-content .gr-buy .list-dex {
        width: 100%;
    }
    .section-banner .main-banner .center-content .gr-buy .list-dex li {
        margin-bottom: 10px;
    }
    .section-banner .main-banner .center-content .gr-buy .list-dex li a {
        padding: 10px;
    }
    .section-banner .main-banner .center-content .gr-buy .list-dex {
        display: block;
        padding-left: 0;
    }
    .section-grafield .main-grafield {
        padding: 40px 20px;
    }
    .section-grafield .main-grafield .left {
        width: 100%;
        margin-bottom: 20px;
    }
    .section-grafield .main-grafield .right {
        width: 100%;
    }
    .section-grafield .main-grafield .right .content-row .title {
        font-size: 25px;
    }
    .section-grafield .main-grafield .right .content-row .desc {
        font-size: 16px;
    }
    .section-map .main-button {
        display: none;
    }
    .section-map .main-map .left {
        width: 100%;
    }
    .section-map .main-map .left {
        width: 100%;
    }
    .section-map .main-map .right {
        width: 100%;
    }
    .section-map .main-map .left .box-img {
        padding: 0 10px;
    }
    .section-map .main-map .left .box-img img {
        width: 50%;
    }
    .section-map .main-map .right .content-map {
        padding: 10px 10px;
    }
    .section-map .main-map .right .content-map .title {
        font-size: 30px;
        margin-bottom: 10px;
    }
    .section-map .main-map .right .content-map .item {
        font-size: 20px;
    }
    .section-ultilies .main-ultilies .round-now .txt {
        font-size: 20px;
    }
    .section-ultilies .main-ultilies .round-now .desc {
        font-size: 16px;
    }
    .section-ultilies .main-ultilies .title {
        font-size: 30px;
    }
    .section-avaiable .main-avaiable .title {
        font-size: 30px;
    }
    .section-avaiable .main-avaiable .round-item .item {
        width: 60%;
        margin-bottom: 15px;
    }
    .section-avaiable .main-avaiable .round-item {
        justify-content: center;
    }
    .footer .main-footer .logo img {
        width: 200px;
    }
    .section-grafield .main-grafield .left .box-img img {
        width: 50%;
    }
    .section-map {
        padding-top: 100px;
        height: 100%;
    }
    .section-ultilies {
        padding: 60px 0;
    }
    .section-avaiable {
        padding: 50px 0;
    }
    .footer {
        padding: 50px 0;
    }
    .footer .main-footer .social-name .item img {
        width: 40px;
        height: 40px;
    }
    .main-button {
        display: block;
    }
    .section-buy-work .main-buy .title {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .section-buy-work .main-buy .title img {
        width: 30px;
    }
    .section-buy-work .main-buy .title span {
        display: none;
    }
    .section-buy-work .main-buy {
        padding: 10px 10px 10px 10px;
    }
    .section-buy-work .main-buy .item-row {
        display: block;
        padding: 10px;
        margin-bottom: 30px;
    }
    .section-buy-work .main-buy .item-row .icon {
        width: 100%;
        margin-bottom: 20px;
    }
    .section-buy-work .main-buy .item-row .icon img {
        width: 50px;
    }
    .section-buy-work .main-buy .item-row .desc-row {
        width: 100%;
    }
    .section-buy-work .main-buy .item-row .desc-row .txt {
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
    }
    .section-buy-work .main-buy .item-row .desc-row .desc {
        font-size: 16px;
        text-align: center;
    }
    .section-buy-work .main-buy .left-buy {
        display: none;
    }
    .section-buy-work {
        background: #000;
        padding: 300px 0 0px;
        position: relative;
    }
    .row-contact {
        width: 100%;
        margin: 10px auto 0;
    }
    .row-contact .item {
        width: 90%;
        margin: 0 auto 10px;
    }
    .section-avaiable .main-avaiable .round-item .item:nth-child(5) {
        margin-left: 0;
        margin-right: 0;
    }
}